<template>
  <div id="previewInfo">
    <div id="panel-header">
      <span class="top-title">财务概览</span>
<!--      <span class="account-amount">账户总额：</span>-->
<!--      <span class="account-amount-number main-font-color">{{ amount.total }}</span>-->
<!--      <span class="account-amount">账户现金：</span>-->
<!--      <span class="account-amount-number main-font-color">{{ amount.money }}</span>-->
<!--      <span class="account-amount">记账金额：</span>-->
<!--      <span class="account-amount-number main-font-color">{{ amount.bill }}</span>-->
<!--      <span class="color-grey account-amount-time">({{ amount.time }})</span>-->
<!--      <span class="account-amount main-font-color" @click="refreshMoneySummary"><Icon type="md-refresh"/></span>-->
      <span class="client_create">
        <Select size="small" v-model="formItem.date_type" @on-change="searchSubmit()" style="width:50px" placeholder="请选择类型">
          <Option value="date" key="date">日</Option>
          <Option value="month" key="month">月</Option>
            <Option value="year" key="year">年</Option>
        </Select>&nbsp;

        <DatePicker size="small" v-model="formItem.created_at" :type="formItem.date_type" placement="bottom-end"
                    placeholder="请选择时间" style="width: 160px"></DatePicker>
        &nbsp;&nbsp;
        <Button type="primary" size="small" @click="searchSubmit()" shape="circle"
                icon="ios-search">搜索
        </Button>
        &nbsp;&nbsp;
        <Button type="default" size="small" @click="cancelSearch()" shape="circle">重置</Button>
    </span>
    </div>

    <div class="mt-20">
      <Row :gutter="16">
        <Col span="8">
          <Card>
            <div class="card">
              <div class="title"><h2>采购概览</h2></div>
<!--              <div class="sumInfo">采购总金额 <span class="main-color">{{ buyPreview.money }}</span></div>-->
              <div class="sumInfo">
                <ul>
                  <li class="cash-money-li">
                    采购总金额：<span class="main-color">{{ buyPreview.money }}</span>
                  </li>
                  <li class="book-money-li">
                    已付金额：<span class="main-color">{{ buyPreview.cash_money }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </Col>
        <Col span="8">
          <Card>
            <div class="card">
              <div class="title"><h2>销售概览</h2></div>
<!--              <div class="sumInfo">销售总金额 <span class="main-color">{{ salePreview.money }}</span></div>-->
              <div class="sumInfo">
                <ul>
                  <li class="cash-money-li">
                    销售总金额：<span class="main-color">{{ salePreview.money }}</span>
                  </li>
                  <li class="book-money-li">
                    已收金额：<span class="main-color">{{ salePreview.cash_money }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </Col>
        <Col span="8">
          <Card>
            <div class="card">
              <div class="title"><h2>杂项概览</h2></div>
              <div class="sumInfo">
                <ul>
                  <li class="cash-money-li">
                    总支出: <span class="main-color">{{ otherOutPreview.money }}</span>
                  </li>
                  <li class="book-money-li">
                    总收入: <span class="main-color">{{ otherInPreview.money }}</span>
                  </li>
                </ul>
              </div>
              <div class="sumInfo">
                <ul>
                  <li class="cash-money-li">
                    已结支出: <span class="main-color">{{ otherOutPreview.cash_money }}</span>
                  </li>
                  <li class="book-money-li">
                    已结收入: <span class="main-color">{{ otherInPreview.cash_money }}</span>
                  </li>
                </ul>
              </div>
              <div class="sumInfo">
                <ul>
                  <li class="cash-money-li">
                    未结支出: <span class="main-color">{{ otherOutPreview.book_money }}</span>
                  </li>
                  <li class="book-money-li">
                    未结收入: <span class="main-color">{{ otherInPreview.book_money }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row class="mt-20">
        <strong>收入：</strong>
        <strong class="main-color">{{ salePreview.cash_money }}</strong><span class="cgrey">(销售收入总额)</span>

        <strong> + </strong>
        <strong class="main-color">{{ otherInPreview.money }}</strong><span class="cgrey">(杂项收入总额)</span>

<!--        <strong> + </strong>-->
<!--        <strong class="main-color">{{ buyReturnPreview.cash_money }}</strong><span class="cgrey">(采购退货收入总额)</span>-->

        <strong> = </strong>
        <strong class="main-color" v-model="total.in">{{ total.in }}</strong><span class="cgrey">元</span>

        <span class="cgrey ml-10">同比</span> <strong :class="yearOnYear.in_percent_flag ? 'up-color':'down-color'">{{ yearOnYear.in_percent }}</strong>
      </Row>
      <Row class="mt5">
        <strong>支出：</strong>
        <strong class="main-color">{{ buyPreview.cash_money }}</strong><span class="cgrey">(采购支出总额)</span>

        <strong> + </strong>
        <strong class="main-color">{{ otherOutPreview.money }}</strong><span class="cgrey">(杂项支出总额)</span>

<!--        <strong> + </strong>-->
<!--        <strong class="main-color">{{ saleReturnPreview.cash_money }}</strong><span class="cgrey">(销售退货支出总额)</span>-->

        <strong> + </strong>
        <strong class="main-color">{{ logisticsPreview.money }}</strong><span class="cgrey">(运费支出总额)</span>


        <strong> = </strong>
        <strong class="main-color">{{ total.out }}</strong><span class="cgrey">元</span>

        <span class="cgrey ml-10">同比</span> <strong :class="yearOnYear.out_percent_flag ? 'up-color':'down-color'">{{ yearOnYear.out_percent }}</strong>
      </Row>
      <Row class="mt5">
        <strong>合计：</strong>
        <strong class="main-color">{{ total.in }}</strong><span class="cgrey">(收入)</span>
        <strong> - </strong>
        <strong class="main-color">{{ total.out }}</strong><span class="cgrey">(支出)</span>
        <strong> = </strong>
        <strong class="main-color">{{ total.all }}</strong><span class="cgrey">元</span>
      </Row>
    </div>
  </div>
</template>


<script>
import NP, {divide} from 'number-precision';
import {
  getAccountAmount,
  refreshMoneySummary
} from '@/api/finance/previewSettlement';
import * as mf_util from '@/utils/moneyFormat';

export default {
  name: "PreviewSettlement",
  data() {
    return {
      common: {
        refresh_flag: false,
      },
      formItem: {
        created_at: '',
        date_type: 'month',
      },
      total: {
        in: 0,
        out: 0,
        all: 0,
      },
      yearOnYear: {
        in: 0,
        out: 0,
        in_percent: 0,
        in_percent_flag: false,
        out_percent_flag: false,
        out_percent: 0,
      },
      buyPreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      buyReturnPreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      salePreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      saleReturnPreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      logisticsPreview: {
        money: 0,
      },
      otherInPreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      otherOutPreview: {
        money: 0,
        cash_money: 0,
        book_money: 0,
      },
      searchFlag: 0,
      amount: {
        money: 0,
        bill: 0,
        time: '',
      }
    };
  },
  methods: {
    refreshMoneySummary() {
      if (!this.common.refresh_flag) {
        this.common.refresh_flag = true;
        refreshMoneySummary().then(res => {
          this.getAccountAmount();
          this.common.refresh_flag = false;
          this.$Message.success('刷新成功！');
        });
      }
    },
    getTotal() {
      let total_in = NP.plus(parseFloat(this.salePreview.cash_money), parseFloat(this.otherInPreview.money), parseFloat(this.buyReturnPreview.cash_money));
      let total_out = NP.plus(parseFloat(this.buyPreview.cash_money), parseFloat(this.otherOutPreview.money), parseFloat(this.saleReturnPreview.cash_money), parseFloat(this.logisticsPreview.money));

      this.total.in = mf_util.format(total_in);
      this.total.out = mf_util.format(total_out);
      this.total.all = mf_util.format(NP.minus(total_in, total_out));

      this.yearOnYear.in_percent_flag = false;
      this.yearOnYear.out_percent_flag = false;

      let in_percent = this.yearOnYear.in ? Math.round(NP.divide(NP.minus(total_in, this.yearOnYear.in), this.yearOnYear.in) * 100, 2) : '--';
      if (in_percent === '-') {
        this.yearOnYear.in_percent = in_percent;
      } else {
        this.yearOnYear.in_percent = in_percent + '%';
        if (in_percent > 0) {
          this.yearOnYear.in_percent_flag = true;
        }
      }

      let out_percent = this.yearOnYear.out ? Math.round(NP.divide(NP.minus(total_out, this.yearOnYear.out), this.yearOnYear.out) * 100, 2) : '--';
      if (out_percent === '-') {
        this.yearOnYear.out_percent = out_percent;
      } else {
        this.yearOnYear.out_percent = out_percent + '%';
        if (out_percent > 0) {
          this.yearOnYear.out_percent_flag = true;
        }
      }
    },

    //获取采购纵览信息
    getFlowPreview($created_at = '') {
      let $date_type = this.formItem.date_type;
      if (this.searchFlag) {
        $created_at = this.formItem.created_at;
      }
      let param = {
        created_at: $created_at,
        date_type: $date_type
      };
      return new Promise((resolve, reject) => {
        this.$axios.post('/api/finance/getFlowPreview', param).then((response) => {
          if (response.data.err_no == 0) {
            let buy = response.data.results.buy;
            let buy_return = response.data.results.buy_return;
            let sale = response.data.results.sale;
            let logistics = response.data.results.logistics;
            let sale_return = response.data.results.sale_return;
            let otherIn = response.data.results.otherIn;
            let otherOut = response.data.results.otherOut;

            //采购概览
            this.buyPreview.money = parseFloat(buy.sum? buy.sum : 0);
            this.buyPreview.cash_money = parseFloat(buy.cash ? buy.cash : 0);
            this.buyPreview.book_money = parseFloat(buy.book ? buy.book : 0);

            //销售概览
            this.salePreview.money = parseFloat(sale.sum ? sale.sum : 0);
            this.salePreview.cash_money = parseFloat(sale.cash ? sale.cash : 0);
            this.salePreview.book_money = parseFloat(sale.book ? sale.book : 0);

            //采购退货概览
            // this.buyReturnPreview.money = parseFloat(buy_return.sum ? buy_return.sum : 0);
            // this.buyReturnPreview.cash_money = parseFloat(buy_return.cash ? buy_return.cash : 0);
            // this.buyReturnPreview.book_money = parseFloat(buy_return.book ? buy_return.book : 0);

            //销售退货概览
            // this.saleReturnPreview.money = parseFloat(sale_return.sum ? sale_return.sum : 0);
            // this.saleReturnPreview.cash_money = parseFloat(sale_return.cash ? sale_return.cash : 0);
            // this.saleReturnPreview.book_money = parseFloat(sale_return.book ? sale_return.book : 0);

            //杂项收入概览
            this.otherInPreview.money = parseFloat(otherIn.sum.money ? otherIn.sum.money : 0);
            this.otherInPreview.cash_money = parseFloat(otherIn.cash.cash_money ? otherIn.cash.cash_money : 0);
            this.otherInPreview.book_money = parseFloat(otherIn.book.book_money ? otherIn.book.book_money : 0);

            //杂项支出概览
            this.otherOutPreview.money = parseFloat(otherOut.sum.money ? otherOut.sum.money : 0);
            this.otherOutPreview.cash_money = parseFloat(otherOut.cash.cash_money ? otherOut.cash.cash_money : 0);
            this.otherOutPreview.book_money = parseFloat(otherOut.book.book_money ? otherOut.book.book_money : 0);

            //运费支出
            // this.logisticsPreview.money = parseFloat(logistics.sum.money ? logistics.sum.money : 0);


            this.yearOnYear.in = parseFloat(response.data.results.yearOnYear.in);
            this.yearOnYear.out = parseFloat(response.data.results.yearOnYear.out);
          }
          resolve();
        });
      })
    },

    //获取物流总费用
    // getLogisticsMoney($created_at = '') {
    //     let _this = this;
    //
    //     let $date_type = _this.formItem.date_type;
    //     if (_this.searchFlag) {
    //         $created_at = _this.formItem.created_at;
    //     }
    //     let param = {
    //         params: {
    //             created_at: $created_at,
    //             date_type: $date_type
    //         }
    //     };
    //
    //     return new Promise((resolve, reject) => {
    //         _this.$axios.get('/api/finance/getLogisticsMoney', param).then(function (response) {
    //             if (response.data.err_no == 0) {
    //                 let sum = response.data.results.sum;
    //
    //                 //采购概览
    //                 _this.LogisticsPreview.money = sum.money ? sum.money : 0;
    //             }
    //             resolve()
    //         });
    //     });
    // },

    searchSubmit() {
      this.searchFlag = 1;
      this.getPreview();
    },
    cancelSearch() {
      this.searchFlag = 0;
      this.formItem.created_at = '';
      this.formItem.date_type = "month";
      this.getPreview();
    },
    getPreview() {
      if (this.formItem.created_at) {
        this.formItem.created_at = this.switchTimeFormat(this.formItem.created_at);
      }

      //等所有异步都加载完成后再去除数据
      Promise.all([this.getFlowPreview()]).then((values) => {
        //排除已经盘点数据
        this.getTotal();
      });
    },
    switchTimeFormat(time) {
      const dateTime = new Date(time);
      const year = dateTime.getFullYear();
      const month = dateTime.getMonth() + 1;
      const date = dateTime.getDate();
      return year + '-' + this.addZero(month) + '-' + this.addZero(date);
    },
    addZero(v) {
      return v < 10 ? '0' + v : v
    },
    getAccountAmount() {
      getAccountAmount().then(res => {
        this.amount.money = mf_util.format(res.data.results.amount);
        this.amount.bill = mf_util.format(res.data.results.bill);
        this.amount.total = mf_util.format(NP.plus(parseFloat(res.data.results.amount), parseFloat(res.data.results.bill)));
        this.amount.time = res.data.results.amount_time;
      });
    }
  },
  mounted() {
    this.getAccountAmount();

    this.getPreview();
  },
};
</script>

<style scoped>
#previewInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
  display: flex;
  align-items: center;
}

#modalForm {
  width: 100%;
}

.client_create {
  position: absolute;
  right: 0px;
  top: -3px;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.card {
  height: 170px;
  text-align: center;
}

.title {
  margin-top: 30px;
}

.sumInfo {
  text-align: center;
  margin: 30px 0px 30px 0px;
  padding: 0px 0 10px 0px;
  font-weight: bold;
  font-size: 14px;
}

ul {
  list-style-type: none
}

.cash-money-li {
  float: left;
  width: 50%;
  text-align: right;
  padding: 0 20px 0 0;
}

.book-money-li {
  float: left;
  padding: 0 0 0 20px;
  width: 50%;
  text-align: left;
  border-left: 1px solid #eee;
}

.account-amount {
  color: #000;
  cursor: pointer;
  font-weight: 300;
  font-size: 12px;
  margin-left: 20px;
}

.account-amount-number {
  font-weight: 600;
  font-size: 14px;
}

.account-amount-time {
  font-weight: 300;
  font-size: 12px;
  margin-left: 10px;
}
</style>
